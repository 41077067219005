import { Badge, Table, TableHead } from 'components';

const UsersTable = ({ environment }) => {
    const columns = [
        { id: 'c-ca-general', alignment: 'center', text: 'CA General' },
        { id: 'c-ca-fund', alignment: 'center', text: 'CA Fund' },
        { id: 'c-compliance', alignment: 'center', text: 'Compliance' },
        { id: 'c-bcl', alignment: 'center', text: 'BCL' },
        { id: 'c-cssf', alignment: 'center', text: 'CSSF' },
    ];

    return (
        <Table>
            <TableHead columns={columns} />
            <tbody>
                <tr>
                    <td style={{ textAlign: 'center' }}>
                        <Badge color="info">{environment.nbCAGeneral}</Badge>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        <Badge color="info">{environment.nbCAFund}</Badge>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        <Badge color="info">{environment.nbCompliance}</Badge>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        <Badge color="info">{environment.nbBCL}</Badge>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        <Badge color="info">{environment.nbCSSF}</Badge>
                    </td>
                </tr>
            </tbody>
        </Table>
    );
};

export default UsersTable;
