import classnames from 'classnames';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const TableHead = ({ className, columns, t }) => {
    const classNames = classnames(className);
    return (
        <thead className={classNames}>
            <tr>
                {columns &&
                    columns.map((column) => (
                        <th key={column.id} className={`align-${column.alignment}`}>
                            {t(column.text)}
                        </th>
                    ))}
            </tr>
        </thead>
    );
};

TableHead.propTypes = {
    columns: PropTypes.PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            alignment: PropTypes.oneOf(['left', 'center', 'right']),
            text: PropTypes.string,
        }),
    ),
};

TableHead.defaultProps = {
    columns: [],
};

export default withTranslation()(TableHead);
