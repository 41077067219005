import { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { FocusError } from 'focus-formik-error';
import * as Yup from 'yup';
import { Label, Input, Button, Alert, BounceLoader } from 'components';
import { useDispatch } from 'react-redux';
import { generateProductKey } from 'features/slices/customers';
import usePrivateApi from 'hooks/usePrivateApi';

const KeyGenForm = ({ customer: customerId, environment: environmentId, setDisplay, t }) => {
    const [requestStatus, setRequestStatus] = useState('idle');
    const [error, setError] = useState(null);

    const dispatch = useDispatch();
    const privateApi = usePrivateApi();
    const expirationDateRef = useRef();

    useEffect(() => {
        expirationDateRef.current?.focus();
    }, []);

    const formik = useFormik({
        initialValues: {
            customer: customerId,
            environment: environmentId,
            expiration: `${(new Date()).getFullYear() + 1}-03-01`,
        },

        validationSchema: Yup.object({
            expiration: Yup.date().required('Required'),
        }),

        onSubmit: async (values) => {
            try {
                setRequestStatus('pending');
                await dispatch(generateProductKey({ privateApi, ...values })).unwrap();
                setDisplay(false);
            } catch (err) {
                setError(err.message);
                setRequestStatus('idle');
            }
        },
    });

    return (
        <>
            {error && (
                <Alert color="danger" className="fill">
                    {t(error)}
                </Alert>
            )}
            <div className="">
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <FocusError formik={formik} />

                    <div className="form-group">
                        <Label htmlFor="expiration">{t('Expiration date')}</Label>
                        <Input
                            ref={expirationDateRef}
                            type="date"
                            id="expiration"
                            name="expiration"
                            onChange={formik.handleChange}
                            value={formik.values.expiration}
                        />
                    </div>

                    <div className="modal-footer">
                        <div className="modal-actions">
                            <div className="modal-actions-item">
                                <Button
                                    color="danger"
                                    type="button"
                                    outline
                                    onClick={() => {
                                        setDisplay(false);
                                    }}>
                                    {t('Cancel')}
                                </Button>
                            </div>
                            <div className="modal-actions-item">
                                <Button
                                    type="submit"
                                    color="success"
                                    disabled={formik.isSubmitting}>
                                    {requestStatus === 'pending' ? (
                                        <BounceLoader color="white" />
                                    ) : (
                                        t('Generate')
                                    )}
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default withTranslation()(KeyGenForm);
