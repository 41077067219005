import { LanguageButton } from 'components';
import ineditLogo from 'assets/images/inedit/in-edit-light.png';
import UserButton from './UserButton';

const Header = () => {
    return (
        <header className="header-container">
            <div className="header-item">
                <img src={ineditLogo} alt="in-edit" className="header-logo" />
            </div>
            <div style={{ flex: '1 1 auto' }}></div>
            <div className="header-item">
                <LanguageButton color="dark" />
            </div>
            <div className="header-item">
                <UserButton />
            </div>
        </header>
    );
};

export default Header;
