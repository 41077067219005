import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { ButtonIcon, Dropdown, Icon } from 'components';
import { RiShutDownLine } from 'react-icons/ri';
import { BiUser } from 'react-icons/bi';
import useLogout from 'hooks/useLogout';

const UserButton = ({ t }) => {
    const [display, setDisplay] = useState(false);

    const navigate = useNavigate();
    const logout = useLogout();

    const handleLogout = async () => {
        await logout();
        navigate('/login');
    };

    return (
        <div className="relative">
            <ButtonIcon icon={BiUser} color="white" onClick={() => setDisplay(true)} />
            <Dropdown className="dropdown-slide mt-10" display={display} setDisplay={setDisplay}>
                <div style={{ width: '200px' }}>
                    <div className="dropdown-item" onClick={handleLogout}>
                        <Icon icon={RiShutDownLine} size="s" className="mr-9 text-danger" />
                        <span className="text-danger">{t('Logout')}</span>
                    </div>
                </div>
            </Dropdown>
        </div>
    );
};

export default withTranslation()(UserButton);
