import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from 'context/AuthProvider';
import { NotificationProvider } from 'context/NotificationProvider';
import { Login, Crm } from 'pages';
import { RequireAuth, PersistLogin } from 'components';
import { Header } from 'layouts';

const App = () => {
    return (
        <NotificationProvider>
            <AuthProvider>
                <Router>
                    <Routes>
                        <Route path="login" element={<Login />} />
                        <Route element={<PersistLogin />}>
                            <Route element={<RequireAuth />}>
                                <Route
                                    path="/"
                                    element={
                                        <>
                                            <Header />
                                            <Crm />
                                        </>
                                    }
                                />
                                <Route path="*" element={<Navigate to="/" replace />} />
                            </Route>
                        </Route>
                    </Routes>
                </Router>
            </AuthProvider>
        </NotificationProvider>
    );
};

export default App;
