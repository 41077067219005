import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: null,
    roles: [],
    accessToken: null,
    persist: JSON.parse(localStorage.getItem('persist')) || false,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action) => {
            const { user, roles, accessToken } = action.payload;

            state.user = user;
            state.roles = roles;
            state.accessToken = accessToken;
        },
        logout: (state) => {
            state.user = null;
            state.roles = [];
            state.accessToken = null;
        },
        switchPersist: (state) => {
            state.persist = !state.persist;
            localStorage.setItem('persist', state.persist);
        },
    },
});

export const { login, logout, switchPersist } = authSlice.actions;
export default authSlice.reducer;
