import { withTranslation } from 'react-i18next';
import { Modal, Card, CardTitle, CardBody } from 'components';
import EditCustomerForm from './EditCustomerForm';

const EditCustomerDialog = ({ customer, display, setDisplay, t }) => {
    return (
        <Modal display={display} setDisplay={setDisplay}>
            <div style={{ minWidth: '400px', maxWidth: '400px' }}>
                <Card>
                    <CardTitle>{t('Edit a customer')}</CardTitle>
                    <CardBody>
                        <EditCustomerForm customer={customer} setDisplay={setDisplay} />
                    </CardBody>
                </Card>
            </div>
        </Modal>
    );
};

export default withTranslation()(EditCustomerDialog);
