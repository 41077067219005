import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// const BASE_URL = 'https://api.in-edit.lu';
const BASE_URL = 'http://localhost:4556';

export const ineditApi = createApi({
    reducerPath: 'ineditApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (builder) => ({
        getCustomers: builder.query({
            query: () => '/customer',
        }),
    }),
});

export const { useGetCustomersQuery } = ineditApi;
