import { useState } from 'react';
import PropTypes from 'prop-types';
import { BsFillKeyFill } from 'react-icons/bs';
import { CgUnavailable } from 'react-icons/cg';
import { FaEye } from 'react-icons/fa';
import { ButtonIcon, ConfirmDialog, Dropdown, Icon } from 'components';
import { AiOutlineEllipsis } from 'react-icons/ai';
import { withTranslation } from 'react-i18next';
import useNotification from 'hooks/useNotification';
import useAuthorization from 'hooks/useAuthorization';
import { useDispatch, useSelector } from 'react-redux';
import { getEnvironment, deleteEnvironment } from 'features/slices/customers';
import DetailsEnvironmentDialog from './DetailsEnvironmentDialog/DetailsEnvironmentDialog';
import EditEnvironmentDialog from './EditEnvironmentDialog/EditEnvironmentDialog';
import KeyGenDialog from './KeyGenDialog/KeyGenDialog';
import usePrivateApi from '../../../hooks/usePrivateApi';

const EnvironmentRow = ({ customer: customerId, environment: environmentId, t }) => {
    const [showDetails, setShowDetails] = useState(false);
    const [showActions, setShowActions] = useState(false);
    const [showEditEnvironmentDialog, setShowEditEnvironmentDialog] = useState(false);
    const [showKeyGenDialog, setShowKeyGenDialog] = useState(false);
    const [showDeleteEnvironmentDialog, setShowDeleteEnvironmentDialog] = useState(false);

    const dispatch = useDispatch();
    const privateApi = usePrivateApi();
    const notification = useNotification();
    const isAuthorized = useAuthorization();
    const environment = useSelector(getEnvironment(customerId, environmentId));

    const toggleShowDetails = () => {
        setShowDetails((prev) => !prev);
    };

    const toggleShowActions = () => {
        setShowActions((prev) => !prev);
    };

    return (
        <>
            <tr>
                <td>{environment.id}</td>
                <td style={{ textAlign: 'left' }}>
                    {environment.productKey ? (
                        <ButtonIcon
                            icon={BsFillKeyFill}
                            size="s"
                            title={environment.productKey}
                            onClick={async () => {
                                try {
                                    await navigator.clipboard.writeText(environment.productKey);
                                    notification({
                                        type: 'success',
                                        message: 'Product key copied to the clipboard',
                                    });
                                } catch (err) {
                                    console.error(err);
                                }
                            }}
                        />
                    ) : (
                        <Icon icon={CgUnavailable} size="s" className="text-danger" />
                    )}
                </td>
                <td style={{ textAlign: 'center' }}>
                    {environment.expirationDate
                        ? new Date(environment.expirationDate).toLocaleDateString()
                        : '-'}
                </td>
                <td style={{ textAlign: 'center' }}>
                    {environment.lastUpdate
                        ? new Date(environment.lastUpdate).toLocaleDateString()
                        : '-'}
                </td>
                <td>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <ButtonIcon icon={FaEye} size="s" onClick={toggleShowDetails} />
                    </div>
                </td>
                <td style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {isAuthorized('ENVIRONMENT_EDITOR', 'ENVIRONMENT_MANAGER') && (
                        <div className="relative">
                            <ButtonIcon
                                icon={AiOutlineEllipsis}
                                size="s"
                                onClick={toggleShowActions}
                            />
                            <Dropdown
                                className="dropdown-slide"
                                display={showActions}
                                setDisplay={setShowActions}>
                                <div style={{ width: '250px' }}>
                                    <div
                                        className="dropdown-item"
                                        onClick={() => {
                                            setShowActions(false);
                                            setShowEditEnvironmentDialog(true);
                                        }}>
                                        <span>{t('Edit this environment')}</span>
                                    </div>
                                    {isAuthorized('ENVIRONMENT_MANAGER') && (
                                        <>
                                            <div
                                                className="dropdown-item"
                                                onClick={() => {
                                                    setShowActions(false);
                                                    setShowKeyGenDialog(true);
                                                }}>
                                                <span>{t('Generate product key')}</span>
                                            </div>
                                            <div className="dropdown-item">
                                                <span
                                                    className="text-danger"
                                                    onClick={() => {
                                                        setShowActions(false);
                                                        setShowDeleteEnvironmentDialog(true);
                                                    }}>
                                                    {t('Delete this environment')}
                                                </span>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </Dropdown>
                        </div>
                    )}
                </td>
            </tr>

            <>
                <DetailsEnvironmentDialog
                    customer={customerId}
                    environment={environmentId}
                    display={showDetails}
                    setDisplay={setShowDetails}
                />
                <EditEnvironmentDialog
                    customer={customerId}
                    environment={environmentId}
                    display={showEditEnvironmentDialog}
                    setDisplay={setShowEditEnvironmentDialog}
                />
                <KeyGenDialog
                    customer={customerId}
                    environment={environmentId}
                    display={showKeyGenDialog}
                    setDisplay={setShowKeyGenDialog}
                />
                <ConfirmDialog
                    display={showDeleteEnvironmentDialog}
                    setDisplay={setShowDeleteEnvironmentDialog}
                    message="Are you sure you want to delete this environment ?"
                    callback={() =>
                        dispatch(deleteEnvironment({ privateApi, customerId, environmentId }))
                    }
                />
            </>
        </>
    );
};

EnvironmentRow.propTypes = {
    customer: PropTypes.number.isRequired,
    environment: PropTypes.string.isRequired,
};

export default withTranslation()(EnvironmentRow);
