import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Modal, Card, CardBody, Button } from 'components';

const ConfirmDialog = ({ display, setDisplay, message, callback, t }) => {
    const handleConfirm = () => {
        try {
            if (callback) {
                callback();
            }
            setDisplay(false);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Modal display={display} setDisplay={setDisplay}>
            <div style={{ width: '400px' }}>
                <Card>
                    <CardBody>
                        <CardBody>{t(message)}</CardBody>
                        <div className="modal-footer">
                            <div className="modal-actions">
                                <div className="modal-actions-item">
                                    <Button
                                        color="danger"
                                        outline
                                        onClick={() => {
                                            setDisplay(false);
                                        }}>
                                        {t('No')}
                                    </Button>
                                </div>
                                <div className="modal-actions-item">
                                    <Button type="submit" color="success" onClick={handleConfirm}>
                                        {t('Yes')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </Modal>
    );
};

export default withTranslation()(ConfirmDialog);
