import PropTypes from 'prop-types';
import classnames from 'classnames';

const Badge = ({ className, color, soft, rounded, children }) => {
    const classNames = classnames(
        className,
        'badge',
        `badge-${soft ? `soft-` : ''}${color}`,
        rounded ? 'badge-rounded' : '',
    );

    return <span className={classNames}>{children}</span>;
};

Badge.propTypes = {
    color: PropTypes.oneOf([
        'primary',
        'secondary',
        'success',
        'warning',
        'danger',
        'info',
        'dark',
    ]),
    soft: PropTypes.bool,
    rounded: PropTypes.bool,
};

Badge.defaultProps = {
    color: 'primary',
    soft: false,
    rounded: false,
};

export default Badge;
