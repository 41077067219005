import { withTranslation } from 'react-i18next';
import { Modal, Card, CardTitle, CardBody } from 'components';
import EditEnvironmentForm from './EditEnvironmentForm';

const EditEnvironmentDialog = ({ customer, environment, display, setDisplay, t }) => {
    return (
        <Modal display={display} setDisplay={setDisplay}>
            <div style={{ maxWidth: '950px' }}>
                <Card>
                    <CardTitle>{t('Edit an environment')}</CardTitle>
                    <CardBody>
                        <EditEnvironmentForm
                            customer={customer}
                            environment={environment}
                            setDisplay={setDisplay}
                        />
                    </CardBody>
                </Card>
            </div>
        </Modal>
    );
};

export default withTranslation()(EditEnvironmentDialog);
