import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { addNewCustomer } from 'features/slices/customers';
import { withTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { FocusError } from 'focus-formik-error';
import * as Yup from 'yup';
import { Label, Input, Button, Alert, BounceLoader } from 'components';
import usePrivateApi from '../../../../hooks/usePrivateApi';

const CreateCustomerForm = ({ setDisplay, t }) => {
    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [error, setError] = useState(null);

    const dispatch = useDispatch();
    const privateApi = usePrivateApi();
    const internalIDRef = useRef();

    useEffect(() => {
        internalIDRef.current?.focus();
    }, []);

    const formik = useFormik({
        initialValues: {
            id: '',
            name: '',
        },

        validationSchema: Yup.object({
            id: Yup.number().typeError('Required number').required('Required'),
            name: Yup.string().required('Required'),
        }),

        onSubmit: async (values) => {
            try {
                setAddRequestStatus('pending');
                await dispatch(addNewCustomer({ privateApi, customer: values })).unwrap();
                setDisplay(false);
            } catch (err) {
                setError(err.message);
                setAddRequestStatus('idle');
            }
        },
    });

    return (
        <>
            {error && (
                <Alert color="danger" className="fill">
                    {t(error)}
                </Alert>
            )}
            <div className="">
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <FocusError formik={formik} />

                    <div className="form-group">
                        <Label htmlFor="id">{t('Internal identifier')}</Label>
                        <Input
                            ref={internalIDRef}
                            id="id"
                            name="id"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.id}
                        />
                        {formik.touched.id && formik.errors.id ? (
                            <div className="text-danger mt-5">{t(formik.errors.id)}</div>
                        ) : null}
                    </div>

                    <div className="form-group">
                        <Label htmlFor="name">{t("Customer's name")}</Label>
                        <Input
                            id="name"
                            name="name"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                        />
                        {formik.touched.name && formik.errors.name ? (
                            <div className="text-danger mt-5">{t(formik.errors.name)}</div>
                        ) : null}
                    </div>

                    <div className="modal-footer">
                        <div className="modal-actions">
                            <div className="modal-actions-item">
                                <Button
                                    color="danger"
                                    type="button"
                                    outline
                                    onClick={() => {
                                        setDisplay(false);
                                    }}>
                                    {t('Cancel')}
                                </Button>
                            </div>
                            <div className="modal-actions-item">
                                <Button
                                    type="submit"
                                    color="success"
                                    disabled={formik.isSubmitting}>
                                    {addRequestStatus === 'pending' ? (
                                        <BounceLoader color="white" />
                                    ) : (
                                        t('Create')
                                    )}
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default withTranslation()(CreateCustomerForm);
