import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { filterArray, sortArrayByProps } from 'utils/tools';

const initialState = {
    customers: [],
    status: 'idle',
    error: null,
    orderBy: 'asc',
    filterBy: 'name',
    search: '',
};

export const customersSlice = createSlice({
    name: 'customers',
    initialState,
    reducers: {
        changeOrder: (state, action) => {
            state.order = action.payload.order;
        },
        changeFilter: (state, action) => {
            state.filter = action.payload.filter;
        },
        changeSearch: (state, action) => {
            state.search = action.payload.search;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchCustomers.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchCustomers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.customers = [...action.payload];
            })
            .addCase(fetchCustomers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action?.error?.message;
            })
            .addCase(addNewCustomer.fulfilled, (state, action) => {
                state.customers = state.customers.slice().concat(action.payload);
            })
            .addCase(deleteCustomer.fulfilled, (state, action) => {
                state.customers = state.customers.filter(
                    (customer) => customer.id !== action.payload,
                );
            })
            .addCase(editCustomer.fulfilled, (state, action) => {
                const { id } = action.payload;

                state.customers = state.customers.map((customer) => {
                    if (customer.id === id) {
                        return action.payload;
                    }

                    return customer;
                });
            })
            .addCase(addNewEnvironment.fulfilled, (state, action) => {
                const { customerId } = action.payload;

                state.customers = state.customers.map((customer) => {
                    if (customer.id === customerId) {
                        customer.environments = customer.environments
                            .slice()
                            .concat(action.payload);
                    }

                    return customer;
                });
            })
            .addCase(editEnvironment.fulfilled, (state, action) => {
                const { id, customerId } = action.payload;

                state.customers = state.customers.map((customer) => {
                    if (customer.id === customerId) {
                        customer.environments = [
                            ...customer.environments.filter((environment) => environment.id !== id),
                            action.payload,
                        ];
                    }

                    return customer;
                });
            })
            .addCase(deleteEnvironment.fulfilled, (state, action) => {
                const { customerId, environmentId } = action.payload;

                state.customers = state.customers.map((customer) => {
                    if (customer.id === customerId) {
                        customer.environments = customer.environments.filter(
                            (environment) => environment.id !== environmentId,
                        );
                    }

                    return customer;
                });
            })
            .addCase(generateProductKey.fulfilled, (state, action) => {
                const {
                    customer: customerId,
                    environment: environmentId,
                    expiration: expirationDate,
                    productKey,
                } = action.payload;

                state.customers = state.customers.map((customer) => {
                    if (customer.id === customerId) {
                        customer.environments.map((environment) => {
                            if (environment.id === environmentId) {
                                environment.productKey = productKey;
                                environment.expirationDate = expirationDate;
                            }

                            return environment;
                        });
                    }

                    return customer;
                });
            });
    },
});

export const fetchCustomers = createAsyncThunk(
    'customers/fetchCustomers',
    async ({ privateApi }, { rejectWithValue }) => {
        try {
            const res = await privateApi.get('/customer');
            return res.data?.customers;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const addNewCustomer = createAsyncThunk(
    'customers/addNewCustomer',
    async ({ privateApi, customer }, { rejectWithValue }) => {
        try {
            const res = await privateApi.post('/customer', customer);
            return res.data?.customer;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const addNewEnvironment = createAsyncThunk(
    'customers/addNewEnvironment',
    async ({ privateApi, environment }, { rejectWithValue }) => {
        try {
            const res = await privateApi.post('/environment', environment);
            return res.data?.environment;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const editEnvironment = createAsyncThunk(
    'customers/editEnvironment',
    async ({ privateApi, environment }, { rejectWithValue }) => {
        try {
            const res = await privateApi.put('/environment', environment);
            return res.data?.environment;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const editCustomer = createAsyncThunk(
    'customers/editCustomer',
    async ({ privateApi, customer }, { rejectWithValue }) => {
        try {
            const res = await privateApi.put('/customer', customer);
            return res.data?.customer;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const deleteCustomer = createAsyncThunk(
    'customers/deleteCustomer',
    async ({ privateApi, id }, { rejectWithValue }) => {
        try {
            await privateApi.delete('/customer', {
                params: { id },
            });
            return id;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const deleteEnvironment = createAsyncThunk(
    'customers/deleteEnvironment',
    async ({ privateApi, customerId, environmentId }, { rejectWithValue }) => {
        try {
            await privateApi.delete('/environment', {
                params: {
                    id: environmentId,
                    customer: customerId,
                },
            });
            return { customerId, environmentId };
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const generateProductKey = createAsyncThunk(
    'customers/generateProductKey',
    async ({ privateApi, ...values }, { rejectWithValue }) => {
        try {
            const res = await privateApi.get('/environment/keygen', {
                params: { ...values },
            });
            return { ...values, productKey: res.data?.productKey };
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const { changeFilter, changeOrder, changeSearch } = customersSlice.actions;
export default customersSlice.reducer;

export const getAllCustomers = (state) => state?.customers.customers;
export const getFilteredCustomers = (state) =>
    filterArray(
        sortArrayByProps(
            state.customers.customers,
            state.customers.filterBy,
            state.customers.orderBy,
        ),
        state.customers.filterBy,
        state.customers.search,
    );
export const getCustomerById = (id) => (state) =>
    state.customers.customers.find((customer) => customer.id === id);
export const getEnvironments = (customerId) => (state) => {
    const customer = state.customers.customers.find((customer) => customer.id === customerId);
    return customer?.environments;
};
export const getFilteredEnvironments = (customerId) => (state) => {
    const customer = state.customers.customers.find((customer) => customer.id === customerId);
    return sortArrayByProps(customer.environments, 'id', 'asc');
};
export const getEnvironment = (customerId, environmentId) => (state) => {
    const customer = state.customers.customers.find((customer) => customer.id === customerId);
    return customer?.environments.find((environment) => environment.id === environmentId);
};
export const getCustomersSearch = (state) => state.customers.search;
export const getCustomersFilterBy = (state) => state.customers.filterBy;
export const getCustomersOrderBy = (state) => state.customers.orderBy;
export const getCustomersStatus = (state) => state.customers.status;
export const getCustomersError = (state) => state.customers.error;
