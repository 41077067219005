import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/auth';
import customersReducer from './slices/customers';
import { ineditApi } from './services/ineditApi';

const store = configureStore({
    reducer: {
        auth: authReducer,
        customers: customersReducer,
        [ineditApi.reducerPath]: ineditApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(ineditApi.middleware),
});

export default store;
