import { withTranslation } from 'react-i18next';
import { Modal, Card, CardTitle, CardBody, ButtonIcon, Icon } from 'components';
import UsersTable from './UsersTable';
import ModulesTable from './ModulesTable';
import { useSelector } from 'react-redux';
import { getEnvironment } from 'features/slices/customers';
import CreditsTable from './CreditsTable';
import { BsFillKeyFill } from 'react-icons/bs';
import { CgUnavailable } from 'react-icons/cg';
import useNotification from 'hooks/useNotification';

const DetailsEnvironmentDialog = ({
    customer: customerId,
    environment: environmentId,
    display,
    setDisplay,
    t,
}) => {
    const environment = useSelector(getEnvironment(customerId, environmentId));
    const notification = useNotification();

    return (
        <Modal display={display} setDisplay={setDisplay}>
            <div style={{ maxWidth: '700px' }}>
                <Card>
                    <CardBody>
                        <Card>
                            <CardTitle>{t('Users')}</CardTitle>
                            <CardBody>
                                <UsersTable users={environment.users} />
                            </CardBody>
                        </Card>
                        <Card>
                            <CardTitle>{t('Modules')}</CardTitle>
                            <CardBody>
                                <ModulesTable modules={environment.modules} />
                            </CardBody>
                        </Card>
                        <Card>
                            <CardTitle>{t('Credits')}</CardTitle>
                            <CardBody>
                                <CreditsTable environment={environment} />
                            </CardBody>
                        </Card>
                        <Card>
                            <CardTitle>Ibanity</CardTitle>
                            <CardBody>
                                <span>Code : </span>{environment.ibanityAuthCode ? (
                                <ButtonIcon
                                    icon={BsFillKeyFill}
                                    size="s"
                                    title={environment.ibanityAuthCode}
                                    onClick={async () => {
                                        try {
                                            await navigator.clipboard.writeText(environment.ibanityAuthCode);
                                            notification({
                                                type: 'success',
                                                message: 'Auth code copied to the clipboard',
                                            });
                                        } catch (err) {
                                            console.error(err);
                                        }
                                    }}
                                />
                            ) : (
                                <Icon icon={CgUnavailable} size="s" className="text-danger" />
                            )}
                            </CardBody>
                        </Card>
                    </CardBody>
                </Card>
            </div>
        </Modal>
    );
};

export default withTranslation()(DetailsEnvironmentDialog);
