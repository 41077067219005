import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getFilteredCustomers } from 'features/slices/customers';
import { withTranslation } from 'react-i18next';

import { Table, TableHead } from 'components';
import CustomerRow from './CustomerRow';

const CustomersTable = ({ t }) => {
    const customers = useSelector(getFilteredCustomers);

    const columns = [
        { id: '', alignment: 'left', text: '' },
        { id: 'id', alignment: 'left', text: 'Internal identifier' },
        { id: 'name', alignment: 'left', text: "Entity's name" },
        { id: 'status', alignment: 'center', text: 'Status' },
        { id: 'envNumber', alignment: 'center', text: 'Number of environments' },
        { id: 'actions', alignment: 'right', text: 'Actions' },
    ];

    return customers?.length > 0 ? (
        <Table>
            <TableHead columns={columns} />
            <tbody>
                {customers.map((customer) => (
                    <CustomerRow key={customer.id} customer={customer.id} />
                ))}
            </tbody>
        </Table>
    ) : (
        <p className="text-danger">{t('No customers to display')}</p>
    );
};

CustomersTable.propTypes = {
    customers: PropTypes.array.isRequired,
};

CustomersTable.defaultProps = {
    customers: [],
};

export default withTranslation()(CustomersTable);
