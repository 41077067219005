import { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { FocusError } from 'focus-formik-error';
import * as Yup from 'yup';
import {
    Label,
    Input,
    Button,
    Alert,
    TableHead,
    Table,
    Card,
    CardBody,
    BounceLoader,
    Select,
} from 'components';
import { addNewEnvironment } from 'features/slices/customers';
import { useDispatch } from 'react-redux';
import usePrivateApi from 'hooks/usePrivateApi';

const CreateEnvironmentForm = ({ customer: id, setDisplay, callback, t }) => {
    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [error, setError] = useState(null);

    const dispatch = useDispatch();
    const privateApi = usePrivateApi();
    const environmentIdRef = useRef();

    const columnsUsers = [
        { id: 'c-standard', alignment: 'center', text: 'Standard' },
        { id: 'c-ts', alignment: 'center', text: 'Timesheet' },
        { id: 'c-ts-r', alignment: 'center', text: 'Timesheet (R)' },
        { id: 'c-ts-rw', alignment: 'center', text: 'Timesheet (R/W)' },
    ];

    const columnsCredits = [
        { id: 'c-ca-general', alignment: 'center', text: 'CA General' },
        { id: 'c-ca-fund', alignment: 'center', text: 'CA Fund' },
        { id: 'c-compliance', alignment: 'center', text: 'Compliance' },
        { id: 'c-bcl', alignment: 'center', text: 'BCL' },
        { id: 'c-cssf', alignment: 'center', text: 'CSSF' },
    ];

    const columnsModules = [
        { id: 'c-publisher', alignment: 'center', text: 'Publisher' },
        { id: 'c-crm', alignment: 'center', text: 'Crm' },
        { id: 'c-timesheet', alignment: 'center', text: 'Timesheet' },
        { id: 'c-tax', alignment: 'center', text: 'Fiscalité' },
        { id: 'c-bookkeeping', alignment: 'center', text: 'Bookkeeping' },
        { id: 'c-workflow', alignment: 'center', text: 'Workflow' },
        { id: 'c-annual-account', alignment: 'center', text: 'Annual account' },
        { id: 'c-bank-transactions', alignment: 'center', text: 'Bank transactions' },
        { id: 'c-vat', alignment: 'center', text: 'Vat' },
    ];

    const options = [
        { label: 'generic', value: 'generic' },
        { label: 'arendt', value: 'arendt' },
        { label: 'bre', value: 'bre' },
        { label: 'demo', value: 'demo' },
        { label: 'fiduciairePME', value: 'fiduciairePME' },
        { label: 'fiduo', value: 'fiduo' },
        { label: 'grant_thornton', value: 'grant_thornton' },
        { label: 'ib', value: 'ib' },
        { label: 'inedit', value: 'inedit' },
        { label: 'kpmg', value: 'kpmg' },
        { label: 'leo', value: 'leo' },
        { label: 'logicor', value: 'logicor' },
        { label: 'mailingSAS', value: 'mailingSAS' },
        { label: 'memola', value: 'memola' },
        { label: 'naledi', value: 'naledi' },
        { label: 'netto', value: 'netto' },
        { label: 'propertypartners', value: 'propertypartners' },
        { label: 'rcarre', value: 'rcarre' },
        { label: 'statec', value: 'statec' },
        { label: 'sudstroum', value: 'sudstroum' },
        { label: 'tango', value: 'tango' },
        { label: 'telecom_luxembourg', value: 'telecom_luxembourg' },
        { label: 'trust_international', value: 'trust_international' },
    ];

    useEffect(() => {
        environmentIdRef.current?.focus();
    }, []);

    const formik = useFormik({
        initialValues: {
            id: '',
            customerId: id,
            type: 'generic',
            users: {
                standard: 0,
                timesheet: 0,
                timesheetRead: 0,
                timesheetReadWrite: 0,
            },
            modules: {
                publisher: true,
                crm: false,
                timesheet: false,
                tax: false,
                bookkeeping: false,
                workflow: false,
                annualAccount: false,
                bankTransactions: false,
                vat: false,
            },
            nbCAGeneral: 0,
            nbCAFund: 0,
            nbCompliance: 0,
            nbBCL: 0,
            nbCSSF: 0,
        },

        validationSchema: Yup.object({
            id: Yup.string().required('Required'),
        }),

        onSubmit: async (values) => {
            try {
                setAddRequestStatus('pending');
                await dispatch(addNewEnvironment({ privateApi, environment: values })).unwrap();
                callback();
                setDisplay(false);
            } catch (err) {
                setError(err.message);
                setAddRequestStatus('idle');
            }
        },
    });

    return (
        <>
            {error && (
                <Alert color="danger" className="fill">
                    {t(error)}
                </Alert>
            )}
            <div className="">
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <FocusError formik={formik} />

                    <div className="form-group">
                        <Label htmlFor="id">{t('Environment ID')}</Label>
                        <Input
                            ref={environmentIdRef}
                            id="id"
                            name="id"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.id}
                        />
                        {formik.touched.id && formik.errors.id ? (
                            <div className="text-danger mt-5">{t(formik.errors.id)}</div>
                        ) : null}
                    </div>

                    <div className="form-group">
                        <Label htmlFor="type">{t('Type')}</Label>
                        <Select
                            id="type"
                            name="type"
                            type="text"
                            nullable={false}
                            options={options}
                            onChange={formik.handleChange}
                            value={formik.values.type}
                        />
                    </div>

                    <Label>{t('Users')}</Label>
                    <Card>
                        <CardBody>
                            <Table>
                                <TableHead columns={columnsUsers} />
                                <tbody>
                                    <tr>
                                        <td>
                                            <Input
                                                id="users.standard"
                                                name="users.standard"
                                                type="text"
                                                onChange={formik.handleChange}
                                                value={formik.values.users.standard}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                id="users.timesheet"
                                                name="users.timesheet"
                                                type="text"
                                                onChange={formik.handleChange}
                                                value={formik.values.users.timesheet}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                id="users.timesheetRead"
                                                name="users.timesheetRead"
                                                type="text"
                                                onChange={formik.handleChange}
                                                value={formik.values.users.timesheetRead}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                id="users.timesheetReadWrite"
                                                name="users.timesheetReadWrite"
                                                type="text"
                                                onChange={formik.handleChange}
                                                value={formik.values.users.timesheetReadWrite}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>

                    <Label>{t('Modules')}</Label>
                    <Card>
                        <CardBody>
                            <Table>
                                <TableHead columns={columnsModules} />
                                <tbody>
                                    <tr>
                                        <td>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}>
                                                <input
                                                    id="modules.publisher"
                                                    name="modules.publisher"
                                                    type="checkbox"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.modules.publisher}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}>
                                                <input
                                                    id="modules.crm"
                                                    name="modules.crm"
                                                    type="checkbox"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.modules.crm}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}>
                                                <input
                                                    id="modules.timesheet"
                                                    name="modules.timesheet"
                                                    type="checkbox"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.modules.timesheet}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}>
                                                <input
                                                    id="modules.tax"
                                                    name="modules.tax"
                                                    type="checkbox"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.modules.tax}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}>
                                                <input
                                                    id="modules.bookkeeping"
                                                    name="modules.bookkeeping"
                                                    type="checkbox"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.modules.bookkeeping}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}>
                                                <input
                                                    id="modules.workflow"
                                                    name="modules.workflow"
                                                    type="checkbox"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.modules.workflow}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}>
                                                <input
                                                    id="modules.annualAccount"
                                                    name="modules.annualAccount"
                                                    type="checkbox"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.modules.annualAccount}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}>
                                                <input
                                                    id="modules.bankTransactions"
                                                    name="modules.bankTransactions"
                                                    type="checkbox"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.modules.bankTransactions}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}>
                                                <input
                                                    id="modules.vat"
                                                    name="modules.vat"
                                                    type="checkbox"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.modules.vat}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>

                    <Label>{t('Credits')}</Label>
                    <Card>
                        <CardBody>
                            <Table>
                                <TableHead columns={columnsCredits} />
                                <tbody>
                                    <tr>
                                    <td>
                                            <Input
                                                id="nbCAGeneral"
                                                name="nbCAGeneral"
                                                type="text"
                                                onChange={formik.handleChange}
                                                value={formik.values.nbCAGeneral}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                id="nbCAFund"
                                                name="nbCAFund"
                                                type="text"
                                                onChange={formik.handleChange}
                                                value={formik.values.nbCAFund}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                id="nbCompliance"
                                                name="nbCompliance"
                                                type="text"
                                                onChange={formik.handleChange}
                                                value={formik.values.nbCompliance}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                id="nbBCL"
                                                name="nbBCL"
                                                type="text"
                                                onChange={formik.handleChange}
                                                value={formik.values.nbBCL}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                id="nbCSSF"
                                                name="nbCSSF"
                                                type="text"
                                                onChange={formik.handleChange}
                                                value={formik.values.nbCSSF}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>

                    <div className="modal-footer">
                        <div className="modal-actions">
                            <div className="modal-actions-item">
                                <Button
                                    color="danger"
                                    type="button"
                                    outline
                                    onClick={() => {
                                        setDisplay(false);
                                    }}>
                                    {t('Cancel')}
                                </Button>
                            </div>
                            <div className="modal-actions-item">
                                <Button
                                    type="submit"
                                    color="success"
                                    disabled={formik.isSubmitting}>
                                    {addRequestStatus === 'pending' ? (
                                        <BounceLoader color="white" />
                                    ) : (
                                        t('Create')
                                    )}
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default withTranslation()(CreateEnvironmentForm);
