import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login, switchPersist } from 'features/slices/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { FocusError } from 'focus-formik-error';
import * as Yup from 'yup';
import { Label, Input, Button, Alert, BounceLoader } from 'components';
import api from 'api';

const LoginForm = ({ t }) => {
    const dispatch = useDispatch();
    const persist = useSelector((state) => state?.auth?.persist);
    const [error, setError] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.pathname || '/';

    const emailRef = useRef();

    useEffect(() => {
        emailRef.current?.focus();
    }, []);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },

        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email').required('Required'),
            password: Yup.string().required('Required'),
        }),

        onSubmit: async (values) => {
            try {
                const res = await api.post('/auth/login', values, {
                    withCredentials: true,
                });

                const { user, roles, accessToken } = res?.data;
                dispatch(login({ user, roles, accessToken }));
                navigate(from, { replace: true });
            } catch (err) {
                setError(err.response?.data.message || 'Undefined error');
            }
        },
    });

    return (
        <>
            {error && (
                <Alert color="danger" className="fill">
                    {t(error)}
                </Alert>
            )}
            <div className="auth-form-body">
                <form onSubmit={formik.handleSubmit}>
                    <FocusError formik={formik} />

                    <div className="form-group">
                        <Label htmlFor="email">{t('Email')}</Label>
                        <Input
                            ref={emailRef}
                            autoComplete="username"
                            id="email"
                            name="email"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger mt-5">{t(formik.errors.email)}</div>
                        ) : null}
                    </div>

                    <div className="form-group">
                        <Label htmlFor="password">{t('Password')}</Label>
                        <Input
                            autoComplete="current-password"
                            id="password"
                            name="password"
                            type="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <div className="text-danger mt-5">{t(formik.errors.password)}</div>
                        ) : null}
                    </div>
                    <div className="auth-persist">
                        <input
                            type="checkbox"
                            id="persist"
                            onChange={() => dispatch(switchPersist())}
                            checked={persist}
                        />
                        <label htmlFor="persist">{t('Stay connected')}</label>
                    </div>
                    <Button type="submit" color="primary" fill disabled={formik.isSubmitting}>
                        {formik.isSubmitting ? <BounceLoader color="white" /> : t('Login')}
                    </Button>
                </form>
            </div>
        </>
    );
};

export default withTranslation()(LoginForm);
